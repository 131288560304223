import axios from 'axios';
import changeCase from 'change-object-case';
import { isObject, cloneDeep } from 'lodash';
import en from '@/schemas/content/en.json';
import { feeTypes } from '@/util/schemas/fees';
import { creditBonusOutcomes, creditBonusTypes } from '@/util/schemas/creditBonus';
import { instantReturnTypes } from '@/util/schemas/instantReturns';
import { giftCardIncentiveTypes } from '@/util/schemas/giftCardIncentiveType';
import store from '@/store';
changeCase.options = { recursive: true, arrayRecursive: true };

export const schema = {
  name: '',
  default: false,
  isCatchAll: false,
  destinations: null,
  locations: [],
  labelsActive: false,
  shippingServiceId: null,
  bypassReview: false,
  eventExchange: 'disabled',
  eventRefund: 'disabled',
  eventCredit: 'disabled',
  delayExchangeEnable: false,
  delayExchangeHours: 0,
  delayRefundEnable: false,
  delayRefundHours: 0,
  delayCreditEnable: false,
  delayCreditHours: 0,
  windowExchange: 30,
  windowRefund: 30,
  windowCredit: 30,
  bonusCredit: 0,
  refundActive: false,
  exchangeActive: false,
  creditActive: false,
  keepItemActive: false,
  shopNowActive: false,
  shopNowForAllEnabled: false,
  onStoreEnabled: false,
  handlingFee: 0,
  allowAdvancedExchanges: false,
  allowInstantExchanges: false,
  instantExchangeAuthorizationType: instantReturnTypes.FULL_AMOUNT,
  instantExchangeWindow: 30,
  instantExchangeRequired: false,
  instantReturnEnabled: false,
  instantReturnAuthorizationType: instantReturnTypes.FULL_AMOUNT,
  instantReturnWindow: 28,
  instantReturnRequired: false,
  keepItemProcessing: 'manual',
  keepItemRangeEnd: 5,
  keepItemUnique: false,
  keepItemItemSplit: false,
  giftCardBonus: 0,
  giftCardIncentiveType: giftCardIncentiveTypes.NONE,
  packingSlipsActive: false,
  packingSlipSetting: {
    enabled: false,
    value: 'return_id',
  },
  packingSlipTaxInclusivity: {
    enabled: false,
    value: 'tax_inclusive',
  },
  shopLaterEnabled: false,
  shopLaterExchangesEnabled: false,
  shopLaterGiftCardEnabled: false,
  customizations: {
    pageConfirmation: {
      instructions: en.pageConfirmation.instructions
    }
  },
  fees: [
    {
      amount: 0,
      isActive: true,
      type: feeTypes.FLAT_FEE,
      storeCreditEnabled: false,
      refundsEnabled: true,
      fallbackAmount: 0,
      maxAmount: 100,
      maxAmountEnabled: false,
      outcome: 'refund'
    },
    {
      amount: 0,
      isActive: true,
      type: feeTypes.FLAT_FEE,
      storeCreditEnabled: true,
      refundsEnabled: false,
      fallbackAmount: 0,
      maxAmount: 100,
      maxAmountEnabled: false,
      outcome: 'credit'
    },
    {
      amount: 0,
      isActive: true,
      type: feeTypes.FLAT_FEE,
      storeCreditEnabled: true,
      refundsEnabled: false,
      fallbackAmount: 0,
      maxAmount: 100,
      maxAmountEnabled: false,
      outcome: 'exchange'
    }
  ],
  creditBonuses: [
    {
      amount: 0,
      isActive: false,
      maxAmount: 0,
      maxAmountEnabled: false,
      type: creditBonusTypes.FLAT_FEE,
      outcome: creditBonusOutcomes.STORE_CREDIT
    },
    {
      amount: 0,
      isActive: false,
      maxAmount: 0,
      maxAmountEnabled: false,
      type: creditBonusTypes.FLAT_FEE,
      outcome: creditBonusOutcomes.UPSELL
    },
    {
      amount: 0,
      isActive: false,
      maxAmount: 0,
      maxAmountEnabled: false,
      type: creditBonusTypes.FLAT_FEE,
      outcome: creditBonusOutcomes.SHOP_LATER
    }
  ],
  reimbursement: {
    shipping_store_credit_enabled: false,
    shipping_refunds_enabled: false,
    shipping_window: 0,
    shipping_window_active: false,
    duties_refunds_enabled: false,
    duties_store_credit_enabled: false,
    duties_window: 0,
    duties_window_active: false,
    shipping_active: false,
    duties_active: false,
  },
  returnWindowEvent: {
    startReturnWindowEventType: 1,
    endReturnWindowEventType: 1,
    fallbackAfterFulfillment: null,
  },
  fulfillableLocations: [],
};

// TODO: Figure out how to make this DB driven or if it's even necessary
export const events = [
  {
    label: 'Manual',
    value: 'disabled',
  },
  {
    label: 'Immediate',
    value: 'immediate',
  },
  {
    label: 'Pre transit',
    value: 'pre_transit',
  },
  {
    label: 'In transit',
    value: 'in_transit',
  },
  {
    label: 'Out for delivery',
    value: 'out_for_delivery',
  },
  {
    label: 'Delivered',
    value: 'delivered',
  }
];

const mapFromApi = (zone) => {
  const customizations = zone.customizations.find((item) => item.lang === 'en')?.content;
  const regions = cloneDeep(store.state.regions.regions);

  return {
    id: zone.id,
    name: zone.title,
    default: zone.default,
    isCatchAll: zone.is_catch_all,
    destinations: changeCase.toCamel(zone.destinations),
    locations: mapToLocations(zone.zones, regions),
    labelsActive: zone.labels_enabled,
    shippingSettingId: zone.shipping_setting_id ?? 0,
    bypassReview: zone.bypass_review,
    eventExchange: zone.exchange_event,
    eventRefund: zone.refund_event,
    eventCredit: zone.gift_card_event,
    windowExchange: zone.exchange_window,
    windowRefund: zone.refund_window,
    windowCredit: zone.gift_card_window,
    refundActive: zone.refunds_enabled,
    exchangeActive: zone.exchanges_enabled,
    creditActive: zone.gift_cards_enabled,
    keepItemActive: zone.keep_item_enabled,
    shopNowActive: zone.shop_now_enabled,
    shopNowForAllEnabled: zone.shop_now_for_all_enabled,
    handlingFee: +zone.handling_fee / 100,
    allowAdvancedExchanges: zone.advanced_exchanges_enabled,
    allowInstantExchanges: zone.instant_exchange_enabled,
    instantExchangeAuthorizationType: zone.instant_exchange_authorization_type,
    instantExchangeWindow: zone.instant_exchange_window,
    instantExchangeRequired: zone.instant_exchange_required,
    instantReturnEnabled: zone.instant_return_enabled,
    instantReturnAuthorizationType: zone.instant_return_authorization_type,
    instantReturnWindow: zone.instant_return_window,
    instantReturnRequired: zone.instant_return_required,
    onStoreEnabled: zone.on_store_enabled,
    keepItemProcessing: zone.keep_item_immediate ? 'automatic' : 'manual',
    keepItemRangeEnd: +zone.keep_item_threshold / 100,
    keepItemUnique: zone.keep_item_unique ?? false,
    giftCardIncentiveType: zone.gift_card_incentive_type,
    giftCardBonus: +zone.gift_card_bonus / 100,
    customizations: customizations ?? { pageConfirmation: { instructions: en.pageConfirmation.instructions } },
    fees: mapToFees(zone),
    reimbursement: mapToReimbursements(zone),
    creditBonuses: mapToBonuses(zone),
    packingSlipsActive: zone.packing_slips_enabled,
    packingSlipSetting: zone.packing_slip_setting ?? { enabled: false, value: 'return_id' },
    packingSlipTaxInclusivity: zone.packingslip_tax_inclusivity ?? { enabled: false, value: 'tax_inclusive' },
    shopLaterEnabled: zone.shop_later_enabled,
    shopLaterExchangesEnabled: zone.shop_later_exchanges_enabled,
    shopLaterGiftCardEnabled: zone.shop_later_gift_card_enabled,
    delayRefundEnable: zone.refund_event_delay_enable,
    delayRefundHours: hoursToDays(zone.refund_event_delay_hours),
    delayExchangeEnable: zone.exchange_event_delay_enable,
    delayExchangeHours: hoursToDays(zone.exchange_event_delay_hours),
    delayCreditEnable: zone.gift_card_event_delay_enable,
    delayCreditHours: hoursToDays(zone.gift_card_event_delay_hours),
    sfnServiceActive: zone.sfn_service_active,
    multiLabelsEnabled: zone.multi_labels_enabled,
    exchangeShippingMethod: zone.exchange_shipping_method,
    returnWindowEvent: mapToReturnWindowEvent(zone.return_window_event),
    fulfillableLocations: zone.fulfillable_locations,
  };
};

const mapToApi = (zone) => {
  return {
    id: zone.id,
    title: zone.name,
    default: zone.default,
    is_catch_all: zone.isCatchAll,
    destinations: zone.destinations.map(item => item.id),
    ...(zone.isCatchAll ? {} : { zones: mapFromLocations(zone.locations) }),
    labels_enabled: zone.labelsActive,
    shipping_setting_id: zone.shippingSettingId,
    bypass_review: zone.bypassReview,
    exchange_event: zone.eventExchange,
    refund_event: zone.eventRefund,
    gift_card_event: zone.eventCredit,
    exchange_window: zone.windowExchange,
    refund_window: zone.windowRefund,
    gift_card_window: zone.windowCredit,
    refunds_enabled: zone.refundActive,
    exchanges_enabled: zone.exchangeActive,
    gift_cards_enabled: zone.creditActive,
    keep_item_enabled: zone.keepItemActive,
    shop_now_enabled: zone.shopNowActive,
    shop_now_for_all_enabled: zone.shopNowForAllEnabled,
    handling_fee: +(zone.handlingFee * 100).toFixed(0),
    advanced_exchanges_enabled: zone.allowAdvancedExchanges,
    instant_exchange_enabled: zone.allowInstantExchanges,
    instant_exchange_authorization_type: zone.instantExchangeAuthorizationType,
    instant_exchange_window: zone.instantExchangeWindow,
    instant_exchange_required: zone.instantExchangeRequired,
    instant_return_enabled: zone.instantReturnEnabled,
    instant_return_authorization_type: zone.instantReturnAuthorizationType,
    instant_return_window: zone.instantReturnWindow,
    instant_return_required: zone.instantReturnRequired,
    keep_item_immediate: zone.keepItemProcessing === 'automatic',
    keep_item_threshold: +(zone.keepItemRangeEnd * 100).toFixed(0),
    keep_item_unique: zone.keepItemUnique,
    on_store_enabled: zone.onStoreEnabled,
    gift_card_bonus: +(zone.giftCardBonus * 100).toFixed(0),
    gift_card_incentive_type: zone.giftCardIncentiveType,
    customizations: [{
      lang: 'en',
      content: zone.customizations
    }],
    fees: mapFromFees(zone.fees),
    reimbursement: changeCase.snakeKeys(zone.reimbursement),
    credit_bonuses: mapFromBonuses(zone.creditBonuses),
    packing_slips_enabled: zone.packingSlipsActive,
    packing_slip_setting: zone.packingSlipSetting,
    packing_slip_tax_inclusivity: zone.packingSlipTaxInclusivity,
    shop_later_enabled: zone.shopLaterEnabled,
    shop_later_exchanges_enabled: zone.shopLaterExchangesEnabled,
    shop_later_gift_card_enabled: zone.shopLaterGiftCardEnabled,
    refund_event_delay_enable: zone.delayRefundEnable,
    refund_event_delay_hours: daysToHours(zone.delayRefundHours),
    exchange_event_delay_enable: zone.delayExchangeEnable,
    exchange_event_delay_hours: daysToHours(zone.delayExchangeHours),
    gift_card_event_delay_enable: zone.delayCreditEnable,
    gift_card_event_delay_hours: daysToHours(zone.delayCreditHours),
    sfn_service_active: zone.sfnServiceActive,
    sfn_enabled: zone.sfnEnabled,
    multi_labels_enabled: zone.multiLabelsEnabled,
    exchange_shipping_method: zone.exchangeShippingMethod,
    return_window_event: mapFromReturnWindowEvent(zone.returnWindowEvent),
    fulfillable_locations: zone.fulfillableLocations?.map(location => {
      return location.id;
    }) ?? [],
  };
};

const mapFromFees = (fees) => {
  if (fees.length === 0) {
    return schema.fees;
  }

  return fees.map(feeObj => {
    const fee = { ...feeObj };
    fee.amount = fee.type === feeTypes.FLAT_FEE ? +(fee.amount * 100).toFixed(0) : fee.amount;
    fee.fallbackAmount = +(fee.fallbackAmount * 100).toFixed(0);
    fee.maxAmount = +(fee.maxAmount * 100).toFixed(0);
    return changeCase.snakeKeys(fee);
  });
};

const mapToFees = (zone) => {
  if (zone.fees.length === 0) {
    return schema.fees;
  }

  return zone.fees.map(fee => {
    fee.amount = fee.type === feeTypes.FLAT_FEE ? (fee.amount / 100).toFixed(2) : fee.amount;
    fee.fallbackAmount = (fee.fallback_amount / 100).toFixed(2);
    fee.maxAmount = (fee.max_amount / 100).toFixed(2);
    return changeCase.toCamel(fee);
  });
};

const mapFromBonuses = bonuses => {
  if (bonuses.length === 0) {
    return schema.creditBonuses.map(bonus => changeCase.snakeKeys({ ...bonus }));
  }

  return bonuses.map(bonus => {
    return changeCase.snakeKeys({
      ...bonus,
      amount: bonus.type === creditBonusTypes.FLAT_FEE ? +(bonus.amount * 100).toFixed(0) : bonus.amount,
      maxAmount: +(bonus.maxAmount * 100).toFixed(0)
    });
  });
};

const mapToBonuses = zone => {
  if (!Array.isArray(zone.credit_bonuses) || zone.credit_bonuses.length === 0) {
    return schema.creditBonuses;
  }

  return zone.credit_bonuses.map(bonus => {
    return changeCase.toCamel({
      ...bonus,
      amount: bonus.type === creditBonusTypes.FLAT_FEE ? (bonus.amount / 100).toFixed(2) : bonus.amount,
      maxAmount: (bonus.max_amount / 100).toFixed(2)
    });
  });
};

const mapToReimbursements = (zone) => {
  if (zone.reimbursement) {
    return changeCase.toCamel(zone.reimbursement);
  }
  return changeCase.toCamel(schema.reimbursement);
};


const mapToReturnWindowEvent = returnWindowEvent => {
  if (returnWindowEvent) {
    return changeCase.toCamel(returnWindowEvent);
  }
  return null;
};

const mapFromReturnWindowEvent = returnWindowEvent => {
  if (returnWindowEvent) {
    return changeCase.snakeKeys({ ...returnWindowEvent });
  }
  return null;
};

const daysToHours = (days) => days * 24;

const hoursToDays = (hours) => Math.round(hours / 24);

// Maps from the array of saved countries to the multi-level region format
const mapToLocations = (locations, regions) => {
  const locationIds = locations.map(location => location.id);

  return regions.filter((region) => {
    if (locationIds.includes(region.id)) {
      region.children = undefined;
      return true;
    }

    if (region.children) {
      region.children = mapToLocations(locations, region.children);
      return region.children.length > 0;
    }

    return false;
  });
};

// Maps from the multi-level region format to an array of country ids
const getLocationIds = ({ id, children }) => children ? children.flatMap(getLocationIds) : [id];
const mapFromLocations = (locations) => locations.flatMap((location) => getLocationIds(location));

export default {
  schema,
  events,
  getAll() {
    return axios.get('/return-policies')
      .then((res) => {
        return res.data.map(mapFromApi);
      });
  },
  getById(id) {
    if (!id) {
      return Promise.reject('API: Zones.getById() requires an id to fetch.');
    }

    return axios.get(`/return-policies/${id}`)
      .then((res) => {
        return mapFromApi(res.data);
      });
  },
  save(zone) {
    if (!zone || !isObject(zone)) {
      return Promise.reject('API: Zones.save() requires a zone object to save.');
    }

    const endpoint = zone.id ? `/return-policies/${zone.id}` : '/return-policies';
    const verb = zone.id ? 'patch' : 'post';
    return axios[verb](endpoint, mapToApi(zone))
      .then((res) => {
        return mapFromApi(res.data);
      });
  },
  remove(zone) {
    if (!zone || !isObject(zone)) {
      return Promise.reject('API: Zones.remove() requires a zone object to remove.');
    }

    return axios.delete(`/return-policies/${zone.id}`);
  },
  disableRefundEvents() {
    return axios.post('/return-policies/refund/disable');
  },
  getCountries() {
    return axios.get(`/return-policies/countries`)
      .then((res) => {
        return res.data;
      });
  }
};
